import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {
  defaultDebounceTime,
  download, getBraidDashboardLinkFromKYCProfile,
  isQuestionnaireUBOAllowedToSyncWithBraid,
  trimTimestampFromFilename
} from '@app/shared-module/utils/utils';
import {ApiService} from '@app/core-module/services/api.service';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {debounceTime, finalize, map} from 'rxjs/operators';
import {AnsweredQuestion, QuestionDefinition} from '@shared/models/question-definition';
import {QuestionFormData} from '@app/shared-module/utils/question.utils';
import {QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO} from '@shared/models/braid/common';
import {TranslateService} from '@ngx-translate/core';
import {Profile} from '@shared/models/profile';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {KycProfile} from '@shared/models/kyc-profile';
import {ProfileQuestionsState} from '@app/admin-module/states/profile/profile-questions.state';

export interface UploadingFileStatus {
  status: boolean;
  file: string;
  forFiBusiness: boolean;
}

@Component({
  selector: 'shared-questions-answered',
  templateUrl: './questions-answered.component.html',
  styleUrls: ['./questions-answered.component.scss']
})
export class QuestionsAnsweredComponent implements OnInit, OnDestroy {

  @Input()
  kycProfile: KycProfile;

  @Input()
  questionsAnswered: Observable<AnsweredQuestion[]>;

  questionsAnsweredOrdered: Observable<AnsweredQuestion[]>;

  // This state is only used for admins
  @Input()
  questionsState: ProfileQuestionsState;
  answeredQuestionsSubscription: Subscription;

  @Output()
  updateAnswerEvent = new EventEmitter<{questionFormData: QuestionFormData, questionId: string}>();

  downloadingFile$ = new BehaviorSubject<boolean>(false);
  downloadingFile = this.downloadingFile$.asObservable().pipe(debounceTime(defaultDebounceTime));

  currProfile: Observable<Profile>;

  syncingUBOWithBraid = signal<boolean>(false);

  protected readonly getBraidDashboardLinkFromKYCProfile = getBraidDashboardLinkFromKYCProfile;

  constructor(private apiService: ApiService,
              private alertsService: AlertsService,
              private currentProfileState: CurrentProfileState,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.questionsAnsweredOrdered = this.questionsAnswered.pipe(
      map(it => it.sort())
    );

    this.currProfile = this.currentProfileState.getCurrentProfile();

    if (this.questionsState) {
      // Stop the loader when new data is received
      this.answeredQuestionsSubscription = this.questionsState.getQuestionsAnswered().subscribe(it => this.syncingUBOWithBraid.set(false));
    }
  }

  ngOnDestroy() {
    if (this.answeredQuestionsSubscription) {
      this.answeredQuestionsSubscription.unsubscribe();
    }
  }

  updateAnswer(questionFormData: QuestionFormData, questionId: string): void {
    this.updateAnswerEvent.emit({questionFormData, questionId});
  }

  downloadFile(filename: string) {
    this.downloadingFile$.next(true);
    this.apiService.getProfileAnswerFile(filename).pipe(
      finalize(() => this.downloadingFile$.next(false))
    ).subscribe(
      res => download(trimTimestampFromFilename(filename), res.body),
      error => this.alertsService.addAlert({type: 'danger', message: 'Error while downloading file'})
    );
  }

  isQuestionAllowedForBraid(block: QuestionDefinition) {
    return block && this.kycProfile ?
      this.kycProfile.braid_fi_business_id && this.kycProfile.braid_business_id &&
      this.kycProfile.segregateAccount &&
      QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO.includes(block.question) && this.isAllowedToSyncWithBraid(block) :
      false;
  }

  isOutOfSyncWithBraid(block: QuestionDefinition) {
    if (!block.lastTimeSubmitted || !block.lastTimeSyncedWithBraid) {
      return true;
    }
    return block.lastTimeSyncedWithBraid !== block.lastTimeSubmitted;
  }

  syncUBOWithBraid(block: QuestionDefinition) {
    this.syncingUBOWithBraid.set(true);
    this.questionsState.syncUBOWithBraid(this.kycProfile.id, block.id)
      .subscribe({
        error: (error) => {
          this.alertsService.addAlert({
            type: 'danger',
            message: this.translateService.instant('shared.questions-answered: Error while creating/syncing Braid UBO'),
          });
          this.syncingUBOWithBraid.set(false);
        }
      });
    return block;
  }

  isAllowedToSyncWithBraid(block: QuestionDefinition) {
    return isQuestionnaireUBOAllowedToSyncWithBraid(block);
  }
}
